import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import '../styling/ProductSolution.css';
import image1 from '../assets/images/image1.png';
import image2 from '../assets/images/image2.png';
import image3 from '../assets/images/image3.png';
import image4 from '../assets/images/image4.png';
import image5 from '../assets/images/image5.png';
import image6 from '../assets/images/image6.png';
import image7 from '../assets/images/image7.png';
import image8 from '../assets/images/image8.png';
import FOM from '../assets/images/FOM.svg';


function ProductSolution() {
  const navigate = useNavigate();

  // const ProductSolution = () => {
  const handleElectricalInfrastructureClick = () => {
    navigate('/electrical-infrastructure');
  }
  const handleDcMonitoringClick = () => {
    navigate('/dc-monitoring');
  }
  const handleSurveillanceSystemClick = () => {
    navigate('/surveillance-system');
  }
  const handleServersClick = () => {
    navigate('/servers');
  }
  const handleRacksClick = () => {
    navigate('/racks');
  }
  const handleUpsClick = () => {
    navigate('/ups');
  }
  const handlePacClick = () => {
    navigate('/pac');
  }
  const handleIswitchEcmClick = () => {
    navigate('/iswitch-ecm');
  }

  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-product">
        <h1 className="title-product">Product Solution</h1>
        <p className="subtitle-product">
          Specializing in Data Center and mission critical facilities
        </p>
        <br></br>

        <div className="group-1">
          <div className="container-1" onClick={handleElectricalInfrastructureClick}>
            <div className="pemanggil">
              <img src={image1} alt="icon" className="icons" />
              <p className="text1">
                ELECTRICAL INFRASTRUCTURE
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handleDcMonitoringClick}>
            <div className="pemanggil">
              <img src={image2} alt="icon" className="icons" />
              <p className="text2">
                DC MONITORING SYSTEM
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handleSurveillanceSystemClick}>
            <div className="pemanggil">
              <img src={image3} alt="icon" className="icons" />
              <p className="text3">
                SURVEILLANCE SYSTEM
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handleServersClick}>
            <div className="pemanggil">
              <img src={image4} alt="icon" className="icons" />
              <p className="text4">
                SERVERS
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
        </div>

        <div className="group-2">
          <div className="container-1" onClick={handleRacksClick}>
            <div className="pemanggil">
              <img src={image5} alt="icon" className="icons" />
              <p className="text5">
                RACKS
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handleUpsClick}>
            <div className="pemanggil">
              <img src={image6} alt="icon" className="icons" />
              <p className="text6">
                UPS
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handlePacClick}>
            <div className="pemanggil">
              <img src={image7} alt="icon" className="icons" />
              <p className="text7">
                PAC
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>
          </div>
          <div className="container-1" onClick={handleIswitchEcmClick}>
            <div className="pemanggil">
              <img src={image8} alt="icon" className="icons" />
              <p className="text8">
                ISWITCH ECM
              </p>
              <img src={FOM} alt="gbr" className="FOM" />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
  // };
};

export default ProductSolution;
