import React from 'react';
import '../App.css';
import '../styling/Ups.css'
import IMG_U from '../assets/images/IMG_U.png';
import vendor4 from '../assets/images/vendor3.png';
import vendor2 from '../assets/images/vendor2.png';
import vendor3 from '../assets/images/vendor3.png';


const Ups = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-ups">
        <div className="content-ups">
          <div className="ups-image">
            <img src={IMG_U} alt="Sample-ups" className="image-ups" />
          </div>
          <div className="content-ups-text">
            <p className="ok">UPS</p>
            <p className="text-1">
              An Uninterruptible Power Supply (UPS) is a device that provides backup power to electronic equipment during a power outage or voltage drop.
              It ensures that connected devices, like computers and servers, continue to operate temporarily until the main power is restored or until they can be safely shut down. UPS systems also help protect against power surges and fluctuations, improving the reliability and longevity of sensitive equipment. They come in various sizes and types, including standby, line-interactive, and online, to suit different needs.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor4} alt="contoh" className="partners-ups" />
              <img src={vendor2} alt="contoh" className="partners-ups" />
              <img src={vendor3} alt="contoh" className="partners-ups" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ups;