import React from "react";
import "../App.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h4>About Us</h4>
          <p>
            PT. Sentral Data Solusindo is a specialist in M&E data center
            engineering.
          </p>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <p>Email: support@sdsolusindo.com</p>
          <p>Phone: 021 39739800</p>
        </div>
        <div className="footer-section">
          <h4>Address</h4>
          {/* <ul className="social-icons">
            <li>
              <a href="https://facebook.com">Facebook</a>
            </li>
            <li>
              <a href="https://twitter.com">Twitter</a>
            </li>
            <li>
              <a href="https://instagram.com">Instagram</a>
            </li>
          </ul> */}
          <a
            href="https://maps.app.goo.gl/6ehpfSPfW1xqpofn6"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>
              Grha Infracom Jalan Tanah Abang 2 No. 46 Kel. Petojo Selatan,Kec.
              Gambir Jakarta Pusat 10160
            </p>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 PT. Sentral Data Solusindo. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
