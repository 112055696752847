import React from 'react';
import '../App.css';
import '../styling/SurveillanceSystem.css'
import IMG_SS from '../assets/images/IMG_SS.png';
import vendor10 from '../assets/images/vendor10.png';
import vendor11 from '../assets/images/vendor11.png';


const SurveillanceSystem = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-ss">
        <div className="content-ss">
          <div className="ss-image">
            <img src={IMG_SS} alt="Sample-ss" className="image-ss" />
          </div>
          <div className="content-ss-text">
            <p className="ok">SURVEILLANCE SYSTEM</p>
            <p className="text-1">
              Because all of us know the importance of the security in the area of the Data Center, then we bring a monitoring system solution so that we
              can ensure that all the activities that occur can be monitored and stored using the Surveillance System.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-ss">
              <img src={vendor10} alt="contoh" className="partners-ss" />
              <img src={vendor11} alt="contoh" className="partners-ss" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveillanceSystem;
