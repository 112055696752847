import React from 'react';
import '../App.css';
import '../styling/Partners.css';
import img2 from '../assets/images/img2.png';
import vendor1 from '../assets/images/vendor1.png';
import vendor2 from '../assets/images/vendor2.png';
import vendor3 from '../assets/images/vendor3.png';
import vendor4 from '../assets/images/vendor4.png';
import vendor5 from '../assets/images/vendor5.png';
import vendor6 from '../assets/images/vendor6.png';
import vendor7 from '../assets/images/vendor7.png';
import vendor8 from '../assets/images/vendor8.png';
import vendor9 from '../assets/images/vendor9.png';
import vendor10 from '../assets/images/vendor10.png';
import vendor11 from '../assets/images/vendor11.png';

const Partners = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-partners">
        <h1 className="title-partners">Our Partners</h1>
        <div className="content-partners">
          <img src={img2} alt="Sample" className="image-partners" />
          <p className="text-partners">
            SDS proudly work together with our vendor in giving us the flexibility to truly tailor a solution to meet our client needs.
            To support our clients with the best possible project design and implementation, we have worked together with a range of vendor partners. Our relationships with our partners ensure that our technicians are across the latest technology and our clients have peace of mind that they will a high quality products.
          </p>
        </div>
      </div>

      <div className="rectangle-data-center">
        <p className="sub-title">
          Trusted Vendor Partners For Data Center
        </p>
        <p className="text-data-center">
          We truly tailor a solution to meet our client needs
        </p>
        <br></br>

        <div className="partners-data-center">
          <a href="https://www.se.com/ww/en/" target="_blank" rel="noopener noreferrer">
          <img src={vendor1} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://www.vertiv.com/en-us/" target="_blank" rel="noopener noreferrer">
          <img src={vendor2} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://www.apc.com/us/en/country-selector/?ref_url=/" target="_blank" rel="noopener noreferrer">
          <img src={vendor3} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://enerplus.co.id/" target="_blank" rel="noopener noreferrer">
          <img src={vendor4} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://www.raritan.com/" target="_blank" rel="noopener noreferrer">
          <img src={vendor5} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://lenovopress.lenovo.com/" target="_blank" rel="noopener noreferrer">
          <img src={vendor6} alt="gambar" className="gambar-partners" />
          </a>
          <a href="https://apac.socomec.com/en/indonesia" target="_blank" rel="noopener noreferrer">
          <img src={vendor7} alt="gambar" className="gambar-partners" />
          </a>
        </div>
      </div>

      <div className="rectangle-cabling">
        <p className="sub-title-cabling">
          Trusted Vendor Partners For Cabling
        </p>
        <p className="text-cabling">
          We truly tailor a solution to meet our client needs
        </p>
        <br></br>

        <div className="partners-cabling">
          <a href="https://www.panduit.com/" target="_blank" rel="noopener noreferrer">
          <img src={vendor8} alt="gambar" className="gambar-cabling" />
          </a>
          <a href="https://www.rdm.com/" target="_blank" rel="noopener noreferrer">
          <img src={vendor9} alt="gambar" className="gambar-cabling" />
          </a>
        </div>
      </div>

      <div className="rectangle-iot">
        <p className="sub-title-iot">
          Trusted Vendor Partners For Surveillance & IoT
        </p>
        <p className="text-iot">
          We truly tailor a solution to meet our client needs
        </p>
        <br></br>

        <div className="partners-iot">
          <a href="https://www.bosch.co.id/id/" target="_blank" rel="noopener noreferrer">
          <img src={vendor10} alt="gambar" className="gambar-iot" />
          </a>
          <a href="https://hikvision-indonesia.com/?gad_source=1&gclid=CjwKCAjwyfe4BhAWEiwAkIL8sG2Tg02rLHhyWchfE4ncipbiVzIVRtEHBiIoSpho20P7HKfVNjLPXRoCj2wQAvD_BwE" target="_blank" rel="noopener noreferrer">
          <img src={vendor11} alt="gambar" className="gambar-iot" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Partners;
