import React from 'react';
import '../App.css';
import '../styling/AboutUs.css';
import img1 from '../assets/images/img1.png';
import PROCESS from '../assets/images/PROCESS.svg';

const AboutUs = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      {/* Rectangle Section */}
      <div className="rectangle-about">
        <h1 className="title">PT. Sentral Data Solusindo</h1>
        <p className="subtitle">
          An introduction to SDS, specialist in M&E data center engineering
        </p>
        <div className="content">
          <div className="content-image">
            <img src={img1} alt="Sample" className="image" />
          </div>
          <div className="content-text">
            <p className="text-about">
              PT Sentral Data Solusindo (SDS) was established in May 2011 by a group of IT Professionals who are very passionate, experienced and certified in Mechanical, Electrical and Structure Cabling.  We have a simple goal, to do great work in our client Data Center environment.
              At the core of SDS’s success are our people and our process.  Our people are our greatest resource.  They are our most valuable asset and we continually invest in them to deliver to our high standards.  We continually review and assess our process to make sure we deliver best results.
            </p>
            <p className="text-about">
              We strive for excellence. We are passionate about our work. We consistently deliver the same reliable and remarkable services to every customer.  No matter the service we are providing, whether it is a small or a big scope, our standard process guarantees optimal results.
              We are partnering with some of the best in class global Data Center Technology providers to fulfill our mission of creating satisfied customers by providing solutions to meet our clients needs and results in an early and accurate alignment between scope, schedule, and budget.
            </p>
          </div>
        </div>
      </div>

      <div className="rectangle-visi-misi">
        <div className="visi">
          <h1 className="title-visi-misi">VISI & MISSION</h1>
          <p className="text-visi">We have a vision to be a preferred partner in Mechanical, Electrical and Structured Cabling in Data Center for our customers.  Therefore, we are committed to providing the best possible solutions to our customer needs.  We will deliver the project on time and on budget and deliver the highest quality Mechanical, Electrical and Structured Cabling Solutions.  Every member of our team is driven to succeed and our values are held close to their heart.</p>
        </div><br></br>
        <div className="misi">
          <p className="sub-service">SERVICE</p>
          <p className="text-misi">
            We are friendly, reliable and we believe in our customers. Our mission is to provide them with the best possible solutions.
          </p>

          <p className="sub-service">EXCELLENCE</p>
          <p className="text-misi">
            We believe that if a task is worth doing, it is worth doing well. Close enough is not good enough at SDS, we always strive for excellence.
          </p>

          <p className="sub-service">INTEGRITY</p>
          <p className="text-misi">
            We are always honest and true to everyone and ourselves. Embracing this deep within our company culture means we focus on the best result.
          </p>
        </div>

      </div>

      <div className="rectangle-proses">
        <h1 className="title">SDS PROCESS</h1><br></br>
        <div className="gambar">
          <img src={PROCESS} alt="dor" className="image-process" />
        </div><br></br>
        <div className="explain">
          <p className="judulnya">WE WILL DELIVER THE RESULTS OF OUR CLIENTS NEEDS</p>
          <p className="penjelasannya">We are experienced team in Data Center Infrastructure. We’ve been in the industry for almost 10 years and have developed a strong reputation for delivering excellent results. We listen to our clients, identify their needs, we design, install and finally we review and maintain all manners of infrastructure. </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
