import React from "react";
// import { countries } from "countries-list"; // Import daftar negara
import "../App.css";
import "../styling/Contact.css";
import Phone from "../assets/images/Phone.svg";
import Web from "../assets/images/Web.svg";
import Mail from "../assets/images/Mail.svg";
import Mappin from "../assets/images/Mappin.svg";

const Contact = () => {
  // const [formData, setFormData] = useState({
  //   salutation: "",
  //   name: "", // Changed from firstname to name
  //   phonenumber: "", // Changed from lastname to phonenumber
  //   email: "",
  //   country: "",
  //   category: "",
  //   message: "",
  // });

  // const [isChecked, setIsChecked] = useState(false); // State untuk checkbox
  // const [isSubmitting, setIsSubmitting] = useState(false); // State untuk menandakan pengiriman

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };

  // const isEmailValid = (email) => {
  //   const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   return regex.test(email);
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Validasi sederhana
  //   if (
  //     !formData.salutation ||
  //     !formData.name ||
  //     !formData.phonenumber ||
  //     !formData.email ||
  //     !formData.country ||
  //     !formData.category ||
  //     !formData.message
  //   ) {
  //     alert("All fields are required.");
  //     return;
  //   }

  //   if (!isEmailValid(formData.email)) {
  //     alert("Please enter a valid email address.");
  //     return;
  //   }

  //   if (isChecked) {
  //     setIsSubmitting(true); // Mulai proses pengiriman
  //     try {
  //       console.log("Sending data:", formData);
  //       const response = await fetch("http://localhost:5001/api/contact", {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(formData),
  //       });

  //       console.log("Response status:", response.status);
  //       if (!response.ok) {
  //         const errorData = await response.json();
  //         console.error("Error Response:", errorData);
  //         alert(errorData.message || "Submission failed. Please try again.");
  //         return;
  //       }

  //       const result = await response.json();
  //       console.log(result);
  //       alert(
  //         "Your message has been sent successfully! We will get back to you shortly."
  //       );

  //       // Reset form setelah submit berhasil
  //       setFormData({
  //         salutation: "",
  //         name: "", // Reset name
  //         phonenumber: "", // Reset phonenumber
  //         email: "",
  //         country: "",
  //         category: "",
  //         message: "",
  //       });
  //       setIsChecked(false); // Reset checkbox
  //     } catch (error) {
  //       console.error("Error submitting form:", error);
  //       alert(error.message || "Submission failed. Please try again.");
  //     } finally {
  //       setIsSubmitting(false); // Selesaikan proses pengiriman
  //     }
  //   } else {
  //     alert("You must agree to the privacy notice before submitting.");
  //   }
  // };

  return (
    <div className="page" style={{ height: "auto" }}>
      <div className="rectangle-contact">
        <div className="content-contact">
          {/* <div className="form">
            <form onSubmit={handleSubmit}>
              <div className="div-1">
                <label className="text-title">
                  Salutation
                  <select
                    name="salutation"
                    value={formData.salutation}
                    onChange={handleInputChange}
                    className="rec-sal"
                  >
                    <option value="">Please Select Salutation</option>
                    <option value="Mr.">Mr.</option>
                    <option value="Ms.">Ms.</option>
                    <option value="Mrs.">Mrs.</option>
                  </select>
                </label>

                <label className="text-title">
                  Name
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="rec-sal"
                  />
                </label>

                <label className="text-title">
                  Phone Number
                  <input
                    type="text"
                    name="phonenumber"
                    value={formData.phonenumber}
                    onChange={handleInputChange}
                    className="rec-sal"
                  />
                </label>
              </div>

              <div className="div-2">
                <label className="text-title">
                  Email Address
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="rec-sal"
                  />
                </label>

                <label className="text-title">
                  Country
                  <select
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className="rec-sal"
                  >
                    <option value="">Select Country</option>
                    {Object.entries(countries).map(([code, country]) => (
                      <option key={code} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </label>

                <label className="text-title">
                  Category
                  <select
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    className="rec-sal"
                  >
                    <option value="">Select Category</option>
                    <option value="Product Solution">Product Solution</option>
                    <option value="Service Solution">Service Solution</option>
                  </select>
                </label>
              </div>

              <div className="div-3">
                <label className="text-title">
                  Message
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={4}
                    cols={40}
                    className="rec-mess"
                  />
                </label>
              </div>

              <div className="privacy">
                <p className="title-privacy">Privacy Notice Consent</p>
                <div className="my-check">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    className="checkbox"
                  />
                  <p className="text-privacy">
                    Having received and read this privacy notice on personal
                    data processing (provided in accordance with Article 13 of
                    EU Regulation 679/2016), I consent to: The processing of my
                    personal data to send marketing communications and to
                    conduct market research.
                  </p>
                </div>
              </div>

              <button
                type="submit"
                className="submit-btn"
                disabled={!isChecked || isSubmitting}
              >
                {isSubmitting ? "Sending..." : "SUBMIT"}
              </button>
            </form>
          </div> */}

          {/* Contact Information */}
          <div className="contact-us">
            <h1 className="title-cont">Contact Us</h1>
            <p className="sub-cont">
              For questions, technical assistance, or collaboration
              opportunities via the contact information provided
            </p>
            <div className="content-phone">
              <img src={Phone} alt="phone" className="phone-icon" />
              <p className="text-phone">021 39739800</p>
            </div>
            <div className="content-web">
              <img src={Web} alt="web" className="web-icon" />
              <a
                href="https://www.sdsolusindo.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-web">www.sdsolusindo.com</p>
              </a>
            </div>
            <div className="content-mail">
              <img src={Mail} alt="mail" className="mail-icon" />
              <p className="text-mail">support@sdsolusindo.com</p>
            </div>
            <div className="content-map">
              <img src={Mappin} alt="map" className="map-icon" />
              <a
                href="https://maps.app.goo.gl/6ehpfSPfW1xqpofn6"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className="text-map">
                  Grha Infracom Jalan Tanah Abang 2 No. 46 Kel. Petojo Selatan,
                  Kec. Gambir Jakarta Pusat 10160
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
