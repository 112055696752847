import React from 'react';
import '../App.css';
import '../styling/Racks.css'
import IMG_R from '../assets/images/IMG_R.png';
import vendor3 from '../assets/images/vendor3.png';
import vendor2 from '../assets/images/vendor2.png';


const Servers = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-racks">
        <div className="content-racks">
          <div className="racks-image">
            <img src={IMG_R} alt="Sample-racks" className="image-racks" />
          </div>
          <div className="content-racks-text">
            <p className="ok">RACKS</p>
            <p className="text-1">
              The first place you'd want to start data center operation is the racking systems and containment solutions.  Starting with flexible, easily installed, adaptable and pre-configured customized server racks and network cabinets saves you costs, footprint and it increases the performance, efficiency, and reliability of your critical infrastructure. Let's discover the data center custom containment solutions that help you reach your objectives.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor3} alt="contoh" className="partners-racks" />
              <img src={vendor2} alt="contoh" className="partners-racks" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servers;
