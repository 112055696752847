import React from 'react';
import '../App.css';
import '../styling/ServiceSolution.css';
import image1service from '../assets/images/image1service.png';
import image2service from '../assets/images/image2service.png';
import image3service from '../assets/images/image3service.png';

const ServiceSolution = () => {
  const items1 = ['Comprehensive Services', 'Preventive Maintenance Services', 'Maintenance Request by Customer'];
  const items2 = ['End to End project management', 'Project Control', 'Testing and Commissioning'];
  const items3 = ['Emergency Support', 'Fault finding and trouble shooting'];
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-service">
        <h1 className="title-service">Service Solution</h1>
        <p className="subtitle-service">
          We provide the services  that meet customer expectations
        </p>
        <br></br>
        <br></br>

        <div className="group-service">
          <div className="container-service-1">
            <img src={image1service} alt="icon-service" className="icons-service" />
            <p className="text-service">
              ELECTRICAL MAINTENANCE
            </p>
            <div className="list-container">
              <ul className="list">
                {items1.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="container-service-1">
            <img src={image2service} alt="icon-service" className="icons-service" />
            <p className="text-service">
              PROJECT MANAGEMENT
            </p>
            <div className="list-container">
              <ul className="list">
                {items2.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="container-service-1">
            <img src={image3service} alt="icon-service" className="icons-service" />
            <p className="text-service">
              SUPPORT SERVICES
            </p>
            <div className="list-container">
              <ul className="list">
                {items3.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceSolution;
