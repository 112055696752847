import React from 'react';
import '../App.css';
import '../styling/Servers.css'
import IMG_S from '../assets/images/IMG_S.png';
import vendor6 from '../assets/images/vendor6.png';


const Servers = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-server">
        <div className="content-server">
          <div className="server-image">
            <img src={IMG_S} alt="Sample-server" className="image-server" />
          </div>
          <div className="content-server-text">
            <p className="ok">DATA CENTER SERVER</p>
            <p className="text-1">
              Server is the heart of the Data Center, that the reason for us to bring the server solution from the small scale until the large one
              to ensure our customer can invest in server systems to deal with the current workloads and expand them to accommodate future growth.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor6} alt="contoh" className="partners-server" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servers;
