import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//Impor styling untuk Home
import '../styling/Home.css';
// Impor gambar slider dari src > assets > images
import slider1 from '../assets/images/slider1.jpg';
import slider2 from '../assets/images/slider2.jpg';
import slider3 from '../assets/images/slider3.jpg';
import slider4 from '../assets/images/slider4.jpg';
// Impor gambar carousel dari src > assets > images
import vendor1 from '../assets/images/vendor1.png';
import vendor2 from '../assets/images/vendor2.png';
import vendor3 from '../assets/images/vendor3.png';
import vendor4 from '../assets/images/vendor4.png';
import vendor5 from '../assets/images/vendor5.png';
import vendor6 from '../assets/images/vendor6.png';
import vendor7 from '../assets/images/vendor7.png';
import vendor8 from '../assets/images/vendor8.png';
import vendor9 from '../assets/images/vendor9.png';
import vendor10 from '../assets/images/vendor10.png';
import vendor11 from '../assets/images/vendor11.png';
import vendor12 from '../assets/images/vendor12.png';
//Impor gambar About Us dari src > assets > images
import img1 from '../assets/images/img1.png';
//Impor icon Why Us dari src > assets > images
// import whyus1 from '../assets/images/whyus1.png';
// import whyus2 from '../assets/images/whyus2.png';
// import whyus3 from '../assets/images/whyus3.png';
// import whyus4 from '../assets/images/whyus4.png';
//Import gambar untuk Awards
import awards1 from '../assets/images/awards1.jpg';
import awards2 from '../assets/images/awards2.jpg';
import awards3 from '../assets/images/awards3.jpg';
import Rectangle44 from '../assets/images/Rectangle44.svg'

function Home() {
  const navigate = useNavigate();

  // const Home = () => {
  // Settings for the slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // Slide otomatis setiap 3 detik
    arrows: false
  };

  // Array vendor untuk carousel
  const vendors = [
    vendor1, vendor2, vendor3, vendor4, vendor5, vendor6,
    vendor7, vendor8, vendor9, vendor10, vendor11, vendor12
  ];

  const handleAboutUsClick = () => {
    navigate('/about-us');
  };

  // const CardSlider = () => {
  const cardSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000, // 3 detik
  };

  const cards = [
    { title: "Best Reseller of the year", description: "This is the 1st awards", image: awards1 },
    { title: "EcoXpert Critical IT Infrasturcture", description: "This is the 2nd awards", image: awards2 },
    { title: "Certificate of Appreciation", description: "Occupational Safety and Health Managment System", image: awards3 },
  ];

  return (
    <div className="page" style={{ height: 'auto' }}>
      {/* Slider Section */}
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src={slider1} alt="Slider 1" className="slider-image" />
          </div>
          <div>
            <img src={slider2} alt="Slider 2" className="slider-image" />
          </div>
          <div>
            <img src={slider3} alt="Slider 3" className="slider-image" />
          </div>
          <div>
            <img src={slider4} alt="Slider 4" className="slider-image" />
          </div>
        </Slider>
      </div>

      {/* Infinite Carousel Section */}
      <div className="carousel-container">
        <div className="carousel-track">
          {vendors.map((vendor, index) => (
            <img
              key={index}
              src={vendor}
              alt={`vendor${index + 1}`}
              className="carousel-image"
            />
          ))}
          {vendors.map((vendor, index) => (
            <img
              key={index + 12} //Duplicate key untuk unique
              src={vendor}
              alt={`vendor${index + 1}`}
              className="carousel-image"
            />
          ))}
        </div>
      </div>

      {/* Rectangle About SDS */}
      <div className="rectangle-1">
        <h1 className="title-1">PT. Sentral Data Solusindo</h1>
        <p className="subtitle-1">
          An introduction to SDS, specialist in M&E data center engineering
        </p>
        <div className="content-1">
          <img src={img1} alt="Sample" className="image-1" />
          <p className="text-1">
            PT Sentral Data Solusindo (SDS) was established in May 2011 by a group of IT Professionals who are very passionate, experienced and certified in Mechanical, Electrical and Structure Cabling.  We have a simple goal, to do great work in our client Data Center environment.
            At the core of SDS success are our people and our process.  Our people are our greatest resource.  They are our most valuable asset and we continually invest in them to deliver to our high standards.  We continually review and assess our process to make sure we deliver best results.
          </p>
          <button className="button" onClick={handleAboutUsClick}>
            About Us
          </button>
        </div>
      </div>

      {/* Rectangle Why Us */}
      <div className="rectangle-2">
      <img src={Rectangle44} alt="Sample" className="recku" />
      </div>

      {/* Card Slider/Award Section */}
      <div className="rectangle-3">
        <h1 className="title-3">AWARDS & CERTIFICATION</h1>
        <div className="card-slider-container">
          <Slider {...cardSettings}>
            {cards.map((card, index) => (
              <div key={index} className="card">
                <div className="images">
                  <img src={card.image} alt={card.title} className="card-image" />
                </div>
                <div className="card-content">
                  <h3>{card.title}</h3>
                  <p>{card.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home;
