import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import AboutUs from './components/AboutUs';
import ProductSolution from './components/ProductSolution';
import ServiceSolution from './components/ServiceSolution';
import Partners from './components/Partners';
import Contact from './components/Contact';
import Footer from './components/Footer';
import ElectricalInfrastructure from './components/ElectricalInfrastructure';
import DcMonitoring from './components/DcMonitoring';
import SurveillanceSystem from './components/SurveillanceSystem';
import Servers from './components/Servers';
import Racks from './components/Racks';
import Ups from './components/Ups';
import Pac from './components/Pac';
import IswitchEcm from './components/IswitchEcm';
import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/product-solution" element={<ProductSolution />} />
        <Route path="/service-solution" element={<ServiceSolution />} />
        <Route path="/partners" element={<Partners />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/electrical-infrastructure" element={<ElectricalInfrastructure />} /> {/* Rute khusus */}
        <Route path="/dc-monitoring" element={<DcMonitoring />} />
        <Route path="/surveillance-system" element={<SurveillanceSystem />} />
        <Route path="/servers" element={<Servers />} />
        <Route path="/racks" element={<Racks />} />
        <Route path="/ups" element={<Ups />} />
        <Route path="/pac" element={<Pac />} />
        <Route path="/iswitch-ecm" element={<IswitchEcm />} />
      </Routes>
      <Footer>
        <Routes>
          <Route path="/footer" element={<Footer />} />
        </Routes>
      </Footer>
    </Router>


  );
}

export default App;
