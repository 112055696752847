import React from 'react';
import '../App.css';
import '../styling/DcMonitoring.css'
import IMG_DC from '../assets/images/IMG_DC.png';
import vendor3 from '../assets/images/vendor3.png';
import vendor5 from '../assets/images/vendor5.png';


const DcMonitoring = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-monitoring">
        <div className="content-monitoring">
          <div className="monitoring-image">
            <img src={IMG_DC} alt="Sample-monitoring" className="image-monitoring" />
          </div>
          <div className="content-monitoring-text">
            <p className="ok">DATA CENTER MONITORING SYSTEM</p>
            <p className="text-1">
              With Data Center Infrastructure Monitoring (DCIM) system, customer can remotely monitor all connected physical infrastructure devices 24/7 to enable secure remote troubleshooting for critical incidents resolution
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-monitoring">
              <img src={vendor3} alt="contoh" className="partners-monitoring" />
              <img src={vendor5} alt="contoh" className="partners-monitoring" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DcMonitoring;
