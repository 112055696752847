import React, { useState } from 'react';
import logo from '../assets/images/logo.png';
import '../App.css';

const Navbar = () => {
  const [dropdown, setDropdown] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for hamburger menu

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Toggle menu open/close
  };

  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <img src={logo} alt="Logo" className="logo-image" />
        </div>

        {/* Hamburger button */}
        <div className={`hamburger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>

        {/* Menu */}
        <div className={`menu ${menuOpen ? 'menu-open' : ''}`}>
          <a href="/home" className="menu-item">HOME</a>
          <a href="/about-us" className="menu-item">ABOUT US</a>
          <div className="menu-item solutions" onMouseEnter={handleDropdown} onMouseLeave={handleDropdown}>
            <span>SOLUTIONS</span>
            {dropdown && (
              <div className={`dropdown ${menuOpen ? 'dropdown-open' : ''}`}>
                <a href="/product-solution" className="dropdown-item">Product Solution</a>
                <a href="/service-solution" className="dropdown-item">Service Solution</a>
              </div>
            )}
          </div>
          <a href="/partners" className="menu-item">PARTNERS</a>
          <a href="/contact" className="menu-item">CONTACT</a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
