import React from 'react';
import '../App.css';
import '../styling/Pac.css'
import IMG_P from '../assets/images/IMG_P.png';
import vendor2 from '../assets/images/vendor2.png';
import vendor3 from '../assets/images/vendor3.png';


const Pac = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-pac">
        <div className="content-pac">
          <div className="pac-image">
            <img src={IMG_P} alt="Sample-pac" className="image-pac" />
          </div>
          <div className="content-pac-text">
            <p className="ok">PAC</p>
            <p className="text-1">
              A Precision Air Conditioner (PAC) is a specialized cooling system designed to maintain precise temperature and humidity levels in environments
              where critical equipment operates, such as data centers, server rooms, and laboratories. Unlike standard air conditioning units, PACs offer enhanced control over climate conditions, ensuring optimal performance and reliability of sensitive electronics. They typically feature advanced features like digital controls, high-efficiency compressors, and redundancy options to prevent downtime. By managing heat loads effectively, PACs help prevent overheating and ensure the longevity of valuable equipment.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor2} alt="contoh" className="partners-pac" />
              <img src={vendor3} alt="contoh" className="partners-pac" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pac;