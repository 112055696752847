import React from 'react';
import '../App.css';
import '../styling/ElectricalInfrastructure.css'
import IMG from '../assets/images/IMG.png';
import vendor1 from '../assets/images/vendor1.png';
import vendor7 from '../assets/images/vendor7.png';


const ElectricalInfrastructure = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-electrical">
        <div className="content-electrical">
          <div className="electrical-image">
            <img src={IMG} alt="Sample-electrical" className="image-electrical" />
          </div>
          <div className="content-electrical-text">
            <p className="ok">Electrical Infrastructure</p>
            <p className="text-1">
              SDS Electrical Infrastructure solutions ensures continuous availability of power and the safe operation of critical applications in all environments thanks to a wide portfolio of power supply systems and services from our trusted vendor partners.   We design and provide the electrical infrastructure based on our clients need.
              Since its foundation for almost 10 years, SDS Electrical Infrastructure Solutions has completed project from small and large scale.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor1} alt="contoh" className="partners-electrical" />
              <img src={vendor7} alt="contoh" className="partners-electrical" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalInfrastructure;
