import React from 'react';
import '../App.css';
import '../styling/IswitchEcm.css'
import IMG_I from '../assets/images/IMG_I.png';
import vendor12 from '../assets/images/vendor12.png';


const IswitchEcm = () => {
  return (
    <div className="page" style={{ height: 'auto' }}>
      <div className="rectangle-i">
        <div className="content-i">
          <div className="i-image">
            <img src={IMG_I} alt="Sample-i" className="image-i" />
          </div>
          <div className="content-i-text">
            <p className="ok">ISWITCH ECM</p>
            <p className="text-1">
              ISWITCH ECM: ISWITCH ECM is designed to help us implement paperless document processing and storage efficiently for an entire organization or department.
              Capture, secure, store, index, manage, and search content intelligently in a repository powered by the latest technologies together with task and workflow creations to automate content processing.
            </p>
            <p className="part">Partners</p>
            <p className="text-1">
              In its endeavors to provide this service, SDS has established partnerships. In this regard, SDS collaborates with the company:
            </p>
            <div className="img-partners">
              <img src={vendor12} alt="contoh" className="partners-i" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IswitchEcm;